import { Col, Typography } from "antd";
import { programPaymenAcademy } from "../../../../shared/services";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { ProgramPaymentType } from "../../../../types/dto/program";
import moment from "moment";
import { formatNumberPrice } from "../../../../shared/utils/Helper";
const { Title } = Typography;

interface TableProgramProps {
  program_id: number;
  resetData: () => void;
}

export const TableHistoryPayment = ({ program_id, resetData }: TableProgramProps) => {

  const columns: TableColumns<ProgramPaymentType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Usuario",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item?.user?.first_name} ${item?.user?.last_name}`}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Email",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.user?.email}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Telefono",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.user?.phone}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Programa",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.program?.name}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Fecha de cobro del programa",
      width: 2,
      render: (_, item) => {
        console.log(item)
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {moment(item.program_schedule?.month).format('YYYY/MM/DD')}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Fecha pago del estudiante",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {moment(item?.payment_date).format('YYYY/MM/DD/MM')}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Monto",
      width: 2,
      render: (_, item) => { 
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {formatNumberPrice(item.amount)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      width: 2,
      fixed: 'right',
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                fontSize: "0.74rem",
                fontWeight: "500",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                item.user.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
              level={5}
            >
              {item?.user?.status}
            </Title>
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Table<ProgramPaymentType>
        columns={columns}
        fetchQueryProps={{
          program_id,
          $sort: { id: -1 },
        }}
        service={programPaymenAcademy}
      />
    </>
  );
};
