import { useSelector } from "react-redux";
import { useUsers } from "../../../../modules/users/hooks/useUsers";
import {
  selectModalUser,
  selectIsUpdateUser,
  selectUserForUpdate,
  selectArrState,
  selectArrCitiesForContactsDirectory,
} from "../../../../redux";
import { WrapperModalUsers } from "./styled";
import { useFormContext } from "react-hook-form";
import { IUserResFind } from "../../../../redux/@types";
import { Button, Col, Typography } from "antd";
import { InputNumber, InputPassword, InputSelect, InputText } from "../inputs";
import React from "react";
import { citiesService, statesService } from "../../../services";

const { Title } = Typography;

const dataOptionsRole = [
  { value: "user", title: "Usuario" },
  { value: "seller", title: "Vendedor" },
  { value: "admin", title: "Administrador" },
  { value: "domiciliary", title: "Domiciliario" },
  { value: "teacher", title: "Profesor" },
];

const dataOptionsTipeUser = [
  { value: "student", title: "Estudiante" },
  { value: "teacher", title: "Profesor" },
];

const dataOptionsStatus = [
  { value: "active", title: "Activo" },
  { value: "inactive", title: "Inactivo" },
  {
    value: "pending security verification",
    title: "Verificación de seguridad pendiente",
  },
  { value: "pending user data", title: "Datos de usuario pendientes" },
  { value: "pending validation", title: "Validación pendiente" },
];

export const ModalUsers = () => {
  const modalUser = useSelector(selectModalUser);
  const isUpdateUser = useSelector(selectIsUpdateUser);
  const updateUser = useSelector(selectUserForUpdate);
  const { isLoading, changeModalUserState, onSubmitCreateOrUpdate } =
    useUsers();

  const [dataCitiesOptions, setDataCitiesOptions] = React.useState<any>([]);
  const [dataStatesOptions, setDataStatesOptions] = React.useState<any>([]);
  const {
    control,
    handleSubmit: onSubmit,
    watch,
    setValue,
  } = useFormContext<IUserResFind>();
  const selectedDepartment = watch("department");
  const cities = watch("city");

  
  React.useEffect(() => {
    if (isUpdateUser) {
      const fetchCity = async () => {
        const { data: dataCity } = await citiesService.find({
          query: { name: cities },
        });
        setValue("department", dataCity[0].state_id);
        setValue("city", dataCity[0].name);
        console.log(dataCity);
        
      };
      fetchCity();      
    }
  }, []);
  

  React.useEffect(() => {
    const fetchStates = async () => {
      const { data: dataDepartments } = await statesService.find();
      setDataStatesOptions(
        dataDepartments.map((item: any) => ({
          value: String(item.id),
          title: item.name,
        }))
      );
    };
    fetchStates();
  }, []);

  React.useEffect(() => {

    if (selectedDepartment) {
      const fetchCities = async () => {
        const { data: dataCities } = await citiesService.find({
          query: { state_id: +selectedDepartment, $limit: 1000 },
        });
        setDataCitiesOptions(
          dataCities.map((item: any) => ({
            value: String(item.name),
            title: item.name,
            state_id: item.state_id,
          }))
        );
      };
      fetchCities();
    }
  }, [selectedDepartment]);


  return (
    <WrapperModalUsers
      title={
        isUpdateUser
          ? `Actualizando usuario: '${updateUser?.id!}'`
          : `Creando usuario`
      }
      open={modalUser}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalUserState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText
          placeHolder="Juan David"
          name="first_name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Apellidos:
        </Title>
        <InputText
          placeHolder="Velázquez Molina"
          name="last_name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cédula:
        </Title>
        <InputNumber
          stringMode={true}
          placeHolder="123456789"
          name="dni"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Correo:
        </Title>
        <InputText
          placeHolder="example@example.com"
          name="email"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Contraseña:
        </Title>
        <InputPassword name="password" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Teléfono:
        </Title>
        <InputNumber
          stringMode={true}
          placeHolder="3292321143"
          name="phone"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Rol:
        </Title>
        <InputSelect
          name="role"
          control={control}
          dataOptions={dataOptionsRole}
          label="Role"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Tipo de usuario:
        </Title>
        <InputSelect
          name="student_teacher"
          control={control}
          dataOptions={dataOptionsTipeUser}
          label="Estudiante"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Esatdo:
        </Title>
        <InputSelect
          name="status"
          control={control}
          dataOptions={dataOptionsStatus}
          label="Estado"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Departamento:
        </Title>
        <InputSelect
          name="department"
          control={control}
          dataOptions={dataStatesOptions}
          label="Ciudad"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Ciudad:
        </Title>
        <InputSelect
          name="city"
          control={control}
          dataOptions={dataCitiesOptions}
          label="Ciudad"
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateUser ? "Actualizar información" : "Crear usuario"}
      </Button>
    </WrapperModalUsers>
  );
};
