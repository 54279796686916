"use client";

import Button from "../../../../shared/components/ui/Button";
import { ProgramType } from "../../../../types/dto/program";
import useCreatePrograms from "../../hooks/programs/useCreatePrograms";
import ProgramForm from "./ProgramForm";


export interface CreateProgramProps {
  setOpenModal: (val: boolean) => void;
}

export default function CreateProgram({ setOpenModal}: CreateProgramProps) {
  const {control, loading, handleSubmit, watch, setValue} = useCreatePrograms({
    setOpenModal,
  });
  console.log("control",control)

  return (
    <form onSubmit={handleSubmit}>
      <ProgramForm control={control}  watch={watch}  setValue={setValue} />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
           Crear
        </Button>
      </div>
    </form>
  );
}
