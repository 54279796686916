import { ReactNode } from "react";
import { MenuProps } from "antd";
import {
  ShoppingOutlined,
  UserOutlined,
  AimOutlined,
  TagsOutlined,
  StarOutlined,
  HighlightOutlined,
  ProfileOutlined,
  FileSearchOutlined,
  PercentageOutlined,
  NumberOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  ReconciliationOutlined,
  SnippetsOutlined,
  WalletOutlined,
  TagOutlined,
  AuditOutlined,
  ScheduleOutlined,
  FileDoneOutlined,
  FileZipOutlined,
  SolutionOutlined,
  RocketOutlined,
  FileProtectOutlined,
  StockOutlined,
} from "@ant-design/icons";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { selectCollapsed, uiChangeCollapsedState } from "../../../../redux";
import { Link, useLocation } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

export const useAdminMenuLayout = () => {
  const dispatch = useAppDispatch();
  const collapsed = useSelector(selectCollapsed);
  let { pathname } = useLocation();

  function getItem(
    icon: ReactNode,
    label: ReactNode,
    key: string,
    children?: MenuItem[]
  ): MenuItem {
    return {
      icon,
      label,
      key,
      children,
    } as MenuItem;
  }

  let items: MenuItem[] = [
    getItem(
      <ShoppingCartOutlined />,
      <Link to={"/orders"}>Pedidos</Link>,
      "/orders"
    ),
    getItem(
      <ShoppingOutlined />,
      <Link to={"/products"}>Productos</Link>,
      "/products"
    ),
    getItem(
      <FileSearchOutlined />,
      <Link to={"/productCategories"}>Categorías</Link>,
      "/productCategories"
    ),
    getItem(
      <ProfileOutlined />,
      <Link to={"/productCharacteristics"}>Características</Link>,
      "/productCharacteristics"
    ),
    getItem(<NumberOutlined />, <>Lista de precios</>, "/listPrice", [
      getItem(
        <DollarCircleOutlined />,
        <Link to={"/priceList"}>Lista de precios</Link>,
        "/priceList"
      ),
      getItem(
        <FileProtectOutlined />,
        <Link to={"/listCustomerGroups"}>List. grup. clientes</Link>,
        "/listCustomerGroups"
      ),
    ]),
    getItem(
      <TagsOutlined />,
      <Link to={"/discountCode"}>Códig. de descuentos</Link>,
      "/discountCode"
    ),
    getItem(<ShopOutlined />, <Link to={"/brands"}>Marcas</Link>, "/brands"),
    getItem(<NumberOutlined />, <>Academia</>, "/academy", [
      getItem(
        <AuditOutlined />,
        <Link to={"/coursesCategories"}>Categorías</Link>,
        "/coursesCategories"
      ),
      getItem(
        <ScheduleOutlined />,
        <Link to={"/courses"}>Cursos</Link>,
        "/courses"
      )
    ]),
    getItem(<NumberOutlined />, <>Gestion Academica</>, "/gestion-academy", [
      getItem(
        <DollarCircleOutlined />,
        <Link to={"/gestion-academy/programs"}>Programas</Link>,
        "/gestion-academy/programs"
      ),
      getItem(
        <DollarCircleOutlined />,
        <Link to={"/gestion-academy/equipments"}>Equipos</Link>,
        "/gestion-academy/equipments"
      ),
      getItem(
        <DollarCircleOutlined />,
        <Link to={"/gestion-academy/pending-payment"}>Pendiente por pago</Link>,
        "/gestion-academy/pending-payment"
      ),
    ]),
    getItem(<NumberOutlined />, <>Trabajo</>, "/work", [
      getItem(
        <SolutionOutlined />,
        <Link to={"/workOffers"}>Ofert. de trabajo</Link>,
        "/workOffers"
      ),
      getItem(
        <FileZipOutlined />,
        <Link to={"/workOffersCategories"}>Categoría</Link>,
        "/workOffersCategories"
      ),
    ]),
    getItem(
      <StarOutlined />,
      <Link to={"/ratings"}>Calificaciónes</Link>,
      "/ratings"
    ),
    getItem(<NumberOutlined />, <>Billetera</>, "/wallet", [
      getItem(
        <WalletOutlined />,
        <Link to={"/walletBalance"}>Balance</Link>,
        "/walletBalance"
      ),
      getItem(
        <WalletOutlined />,
        <Link to={"/walletMovements"}>Movimientos</Link>,
        "/walletMovements"
      ),
      getItem(
        <TagOutlined />,
        <Link to={"/walletBonus"}>Bonos</Link>,
        "/walletBonus"
      ),
    ]),
    getItem(<NumberOutlined />, <>Directorios</>, "/directory", [
      getItem(
        <ReconciliationOutlined />,
        <Link to={"/contactsDirectory"}>Profesional</Link>,
        "/contactsDirectory"
      ),
      getItem(
        <SnippetsOutlined />,
        <Link to={"/directoryCategories"}>Categorías</Link>,
        "/directoryCategories"
      ),
    ]),

    getItem(
      <HighlightOutlined />,
      <Link to={"/banners"}>Banners</Link>,
      "/banners"
    ),
    getItem(<FileDoneOutlined />, <Link to={"/cms"}>CMS</Link>, "/cms"),
    getItem(<UserOutlined />, <Link to={"/users"}>Users</Link>, "/users"),
    getItem(
      <AimOutlined />,
      <Link to={"/addressesUsers"}>Dirc. de usuarios</Link>,
      "/addressesUsers"
    ),
    getItem(<NumberOutlined />, <>Configuración</>, "/configurations", [
      getItem(<PercentageOutlined />, <Link to={"/tax"}>IVA</Link>, "/tax"),
      getItem(
        <HomeOutlined />,
        <Link to={"/cities"}>Ciudades</Link>,
        "/cities"
      ),
      getItem(
        <EnvironmentOutlined />,
        <Link to={"/states"}>Estados</Link>,
        "/states"
      ),
      getItem(
        <RocketOutlined />,
        <Link to={"/fulfillmentCompany"}>Transportadora</Link>,
        "/fulfillmentCompany"
      ),
      getItem(
        <StockOutlined />,
        <Link to={"/systemVariables"}>Variables del sis.</Link>,
        "/systemVariables"
      ),
      getItem(
        <StockOutlined />,
        <Link to={"/configuration-cashback"}>Config. cashback</Link>,
        "/configuration-cashback"
      ),
    ]),
  ];

  let items2: MenuItem[] = [
    getItem(
      <ShoppingCartOutlined />,
      <Link to={"/orders"}>Pedidos</Link>,
      "/orders"
    ),
  ];
  
  let items3: MenuItem[] = [
    getItem(<NumberOutlined />, <>Gestion Academica</>, "/gestion-academy", [
      getItem(
        <DollarCircleOutlined />,
        <Link to={"/gestion-academy/programs"}>Programas</Link>,
        "/gestion-academy/programs"
      ),
    ]),
  ];

  const changeCollapsed = () => {
    dispatch(uiChangeCollapsedState());
  };
  return {
    //state
    collapsed,
    items,
    items2,
    pathname,
    items3,
    //methods
    //functions
    changeCollapsed,
  };
};
