import { Col, Checkbox, Table, Spin } from "antd";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramScheduleType } from "../../../../types/dto/program";
import React from "react";
import { programPaymentScheduleAcademy, programPaymentScheduleStudentAcademy } from "../../../../shared/services";
import { useParams } from "react-router-dom";
import moment from "moment";
import useProgramStudent from "../../../academyProgramDetail/hooks/students/useProgramStudent";
import { formatNumberPrice } from "../../../../shared/utils/Helper";
import useCreatePaymentScheduleStudent from "../../../AcademyStudentSchedulePayment/hooks/useCreatePaymentScheduleStudent";

export const TablePaymentScheduleStudent = () => {
  const { user_id, program_id } = useParams();
  const [dataProgramSchedule, setDataProgramSchedule] = React.useState<ProgramScheduleType[]>([]);
  const [dataStudentSchedules, setDataStudentSchedules] = React.useState<any[]>([]);

  const { programStudentsByUserIdAndProgramId } = useProgramStudent({
    program_id: +program_id!,
    user_id: +user_id!,
  });

  const {
    updateOrCreateScheduleStudent,
    loadingUpdateOrCreate,
  } = useCreatePaymentScheduleStudent();

  const handleAssignSelected = async (id: number) => {
    await updateOrCreateScheduleStudent({
      program_student_id: programStudentsByUserIdAndProgramId[0]?.id,
      payment_schedule_id: id,
    });
    await fetchStudentScheduleData(); // Refrescar datos
  };

  const fetchStudentScheduleData = async () => {
    const filterData = programStudentsByUserIdAndProgramId.map((it) => it.id);
    const {data} = await programPaymentScheduleStudentAcademy.find({
      query: {
        $limit: 100,
        program_student_id: { $in: filterData },
      },
    });
    setDataStudentSchedules(data); 
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const {data} = await programPaymentScheduleAcademy.find({
        query: { program_id: +program_id! },
      });
      setDataProgramSchedule(data);
      await fetchStudentScheduleData(); // Cargar cronograma de pagos de estudiantes
    };
    fetchData();
  }, [program_id, programStudentsByUserIdAndProgramId]);

  const columns: TableColumns<ProgramScheduleType> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 1,
      key: "id",
    },
    {
      title: "Mes de cobro",
      dataIndex: "month",
      key: "month",
      width: 3,
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {moment(item.month).format("MMM / YYYY")}
            </span>
          </Col>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Fecha límite de pago",
      dataIndex: "day_of_month_payment_limit",
      key: "day_of_month_payment_limit",
      width: 3,
      render: (_, item: ProgramScheduleType) => (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <span className="capitalize">
            {moment(item.day_of_month_payment_limit).format("YYYY/MM/DD")}
          </span>
        </Col>
      ),
    },
    {
      title: "Valor extraordinario",
      dataIndex: "price",
      key: "price",
      width: 3,
      render: (_, item: ProgramScheduleType) => (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <span className="capitalize">{formatNumberPrice(+item.price)}</span>
        </Col>
      ),
    },
    {
      title: "Valor pronto pago",
      dataIndex: "price_with_prompt_payment_discount",
      key: "price_with_prompt_payment_discount",
      width: 3,
      render: (_, item: ProgramScheduleType) => (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <span className="capitalize">
            {formatNumberPrice(+item.price_with_prompt_payment_discount)}
          </span>
        </Col>
      ),
    },
    {
      title: "Asignar cronograma al estudiante",
      key: "operation",
      width: 6,
      fixed: "right",
      render: (_, item, i) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 15,
            }}
          >
            <Checkbox
              checked={!!dataStudentSchedules.find(
                (schedule) =>
                  schedule.payment_schedule_id === item.id &&
                  schedule.status === "active"
              )}
              onChange={(e) => handleAssignSelected(item.id)}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      {loadingUpdateOrCreate && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Fondo con opacidad
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Spin spinning={true} />
        </div>
      )}
      <Table dataSource={dataProgramSchedule} columns={columns} />;
    </>
  );
};
